<template>
    <div class="content">
        <NewsList :breads="breads" :active="active" :categories="items" @itemClick="categoryClick">
            <template v-slot:content>
                <div class="news-content">
                    <div class="query-box form-inline">
                        <!--
                        <div class="form-group">
                            <label for="category" class="control-label">制度分类</label>
                            <select id="category" class="form-control">
                                <option>请选择</option>
                                <option value="1">制度1</option>
                            </select>
                        </div>
                        -->
                        <div class="form-group">
                            <label for="key" class="control-label">关键词</label>

                          <el-autocomplete
                              v-model="keyword"
                              :fetch-suggestions="querySearch"
                              clearable
                              class="inline-input w-50"
                              placeholder="可选/可输入"
                              @select="handleSelect"
                          />


<!--                            <input type="text" id="key" class="form-control" v-model="keyword" placeholder="请输入" @keyup.enter="pageChange(1)"/>-->
                        </div>



                        <div class="form-group" style="padding-left: 20px;">
                            <label for="date" class="control-label">时间</label>
                            <Datepicker 
                            lang="zh-CN" 
                            v-model="date" 
                            inputClass="form-control" 
                            id="date"
                            :range="true"
                            :circle="true"
                            position="right"
                            placeholder="请选择日期"
                            ></Datepicker>
                        </div>
                        <button type="button" class="btn btn-primary btn-search" @click="pageChange(1)" >查询</button>
                        
                    </div>
                    <table class="table table-bordered" v-loading="loading">
                        <thead>
                            <tr>
                                <td>标题</td>
                                <td width="162px">时间</td>
                            </tr>
                        </thead>
                        <tbody v-if="items.length > 0 && items[active].content">
                            <tr v-for="item in items[active].content.list"  :key="item.id">
                                <td><a href="javascript:void(0);" @click="gotoDetail(item.id)" v-html="matchKey(item.title)"></a></td>
                                <td>{{item.publishTime.substring(0,10)}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <Pager class="i-pager" @pageChange="pageChange" :currentPage="currentPage" :totalPage="totalPage" ></Pager>
                    
                    
                </div>
            </template>
            
        </NewsList>
    </div>
</template>

<script>
import NewsList from '../components/common/NewsList'
import Pager from '../components/common/Pager'
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css'
import {ref, onMounted, getCurrentInstance} from 'vue'
import {useRoute} from 'vue-router'
import qs from 'qs'

export default {
    name:"Platform",

    data(){
        return {
            date:[],
            currentPage: 1,
        }
    },
    components:{NewsList, Pager, Datepicker: VueDatepickerUi},
    setup(){
        const {proxy} = getCurrentInstance();
        const items = ref([]);
        const loading = ref(false);
        const route = useRoute();
        const id = ref('');
        const active = ref(0);
        const keyword = ref('');
        const keywordTypeVal = ref('');
        const restaurants = ref([
          { value:'GDSTI文档', label: 'GDSTI文档',},
          { value:'论文报告', label: '论文报告',},
          { value:'pdf', label: 'pdf',},
          { value:'国外参考', label: '国外参考',},
          { value:'指导建议', label: '指导建议',},
          { value:'规章制度', label: '规章制度',},
          { value:'政策法规', label: '政策法规',},
          { value:'标准规范', label: '标准规范',},
          { value:'指南方针', label: '指南方针',},
          { value:'调查汇编', label: '调查汇编',},
          { value:'图片', label: '图片',},
        ]);
        const date = ref([])
        const breads = ref([{
                text:'首页',
                path: '/index',
                name: 'Index'
            }]);

        const catalogType = ref([   //文章类别
            { id:'LAST_NEWS', name: '最新发布' },
            { id:'WEB-NOTICE', name: '通知公告' },
            { id:'WEB-OPENSOURCE', name: '开放课题' },
            { id:'WEB-DOWNLOAD-CENTER', name: '下载中心' },
            ]);

        if(route.query.id){
            id.value = route.query.id;
        }

      const querySearch = (queryString, cb) => {
        const results = queryString
            ? restaurants.value.filter(createFilter(queryString))
            : restaurants.value
        // call callback function to return suggestions
        cb(results)
      }

      const createFilter = (queryString) => {
        return (restaurant) => {
          return (
              restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          )
        }
      }


      const handleSelect = (item) => {
        console.log(item)
      }


      function getCategories(){
            items.value = catalogType.value;

            for(var i = 0 ; i < items.value.length; i++){
                if(id.value == items.value[i].id){
                    active.value = i;
                    breads.value.push({text: items.value[i].name})
                    proxy.getList(i);
                }
            }
        }



        onMounted(()=>{
            getCategories();
        });

        return {loading, items, active, breads, keyword, date,id,keywordTypeVal,querySearch,handleSelect};
    },
    computed:{
        totalPage(){
            if(this.items.length > 0 && this.items[this.active].content){
                return this.items[this.active].content.totalPage;
            }else{
                return 1;
            }
        }
    },
    methods:{
        categoryClick: function(index){
            this.active = index;
            if(this.breads.length == 1){
                this.breads.push({text: this.items[this.active].name})
            }else{
                this.breads[1].text = this.items[this.active].name;
            }
            this.currentPage = 1;
            this.getList(index);
        },
        gotoDetail(id){
            // this.$router.push({name: 'NewsDetail', path: '/newsDetail', query: {id: id}})
            let routeData = this.$router.resolve({name: 'NewsDetail', path: '/newsDetail', query: {id: id}});
            window.open(routeData.href, '_blank');

        },
        getList(index){
            this.loading = true;
            const item = this.items[index];
            const url = '/core/cms/a/articleContent/list';
            let startDate = '';
            let endDate = '';
            if(this.date.length == 2){
                startDate = this.$formateDate(this.date[0]);
                endDate = this.$formateDate(this.date[1]); 
            }
            this.$http.post(url, {typeCode: item.id, limit: 10, offset: (this.currentPage - 1) *10,
                searchValue: this.keyword, 
                startTime: startDate,
                apprStatus: '1,99',
                publishStatus: '1',
                timeField: 'publish_time',
                endTime:endDate} )
            .then(res=>{
                this.loading = false
                if(res.data.state == 0){
                    item.content = res.data.result;
                }
            }).catch(err=>{
                this.loading = false
            });
        },
        pageChange(page){
            this.currentPage = page;
            this.getList(this.active);
        },
        matchKey(val){
            if(this.keyword){
                let keyword = this.keyword;
                let replaceReg = new RegExp(keyword, 'ig')
                let replaceString = `<span style="color: #E02020;">${keyword}</span>`
                return val.replace(replaceReg, replaceString);
            }else{
                return val;
            }
        }
    }
}
</script>

<style lang="less" scoped>
.i-pager{
    margin-top: 38px;
    margin-bottom: 27px;
}
.table td{
    font-family: SourceHanSansCN-Regular;
    font-size: 16px;
    color: rgba(0,0,0,0.85);
}
.query-box{
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
#category{
    width: 100px;
}
#key{
    width: 171px;
}
#date{
    display: inline-block;
}
.btn-search{
    margin-left: 48px;
}

</style>

